import React, { useState, useEffect } from 'react';
import './Form.css';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const Form = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('trimite mesaj');
    const [countdown, setCountdown] = useState(0);

    const changeName = e => {
        setName(e);
    }
    const changeEmail = e => {
        setEmail(e);
    }
    const changePhone = e => {
        setPhone(e);
    }
    const changeMessage = e => {
        setMessage(e);
    }
    const sendMessage = async () => {
        let checkQuery = [name, email, message];
        let empty = 0;

        checkQuery.forEach(i => {
            if (i === '') empty++;
        });

        const url = 'https://pudlmbxrmf.execute-api.us-east-1.amazonaws.com/initial';
        const options = {
            method: 'POST',
            mode: 'no-cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                message,
                phone
            })
        }

        if (empty === 0) {
            await fetch(url, options);
            setNotification('mesaj expediat');
            const mycountdown = setTimeout(() => {
                setNotification('trimite mesaj');
                setName(''); setEmail(''); setPhone(''); setMessage('');
            }, 5000);
            setCountdown(mycountdown);
        } else {
            setNotification('date introduse greșit/incomplet');
            const mycountdown = setTimeout(() => {
                setNotification('trimite mesaj');
            }, 5000);
            setCountdown(mycountdown);
        }
    }

    useEffect(() => {
        return () => clearTimeout(countdown);
    }, [countdown]);

    return (
        <>
            <div className = "formdivision">
                <h1>Ai întrebări sau sugestii? Scrie-ne un mesaj.</h1>
                <form>
                    <label>
                        Nume *
                    </label>
                    <input type = "text" name = "name" placeholder = "" required spellCheck = "false" value = { name || '' } onChange = { e => changeName(e.target.value) } />
                    <label>
                        Adresă e-mail *
                    </label>
                    <input type = "email" name = "email" placeholder = "" required spellCheck = "false" value = { email || '' } onChange = { e => changeEmail(e.target.value) } />
                    <label>
                        Telefon *
                    </label>
                    <input type = "tel" name = "phone" placeholder = "" required pattern="\d+" value = { phone || '' } onChange = { e => changePhone(e.target.value) } />
                    <label>
                        Mesaj *
                    </label>
                    <textarea name = "message" form = "contact" placeholder = "" spellCheck = "false" value = { message || '' } onChange = { e => changeMessage(e.target.value) } ></textarea>
                </form>
                <div className = "send-message" onClick = { () => sendMessage() }>
                    <div className = "send-message-inner">
                        { notification }
                        <span>
                            <NavigateNextIcon />
                            <NavigateNextIcon style = {{ marginLeft: '-12px' }}/>
                            <NavigateNextIcon style = {{ marginLeft: '-12px' }}/>
                        </span>
                    </div>
                    
                </div>
            </div> 
        </>
    );
}

export default Form;