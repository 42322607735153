import React, { useEffect } from 'react';
import { useLocation } from  'react-router-dom';
import './Product.css';
import NotFound from '../notfound/NotFound';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { NavLink } from 'react-router-dom';

const Product = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { state } = useLocation();

    return (
        <>
            { state ?
                <div>
                    <div className = "product-header">
                        <NavLink to = "/shop"><li className = "cat-select">toate produsele</li></NavLink>
                    </div>
                    <div className = "product-type">
                        <div className = "product-half">
                            <div className = "single-img"></div>
                        </div>
                        <div className = "product-half">
                            <div className = "single-name">{ state.name }</div>
                            <div className = "single-cat">{ `#${state.category}` }</div>
                            <div className = "single-price">{ state.price }</div>
                            <div className = "single-description">{ state.description }</div>
                            <div className = "single-actions">
                                <div className = "product-add"><LocalMallOutlinedIcon style = {{ verticalAlign: 'middle', marginRight: '15px' }}/> Adaugă în coş</div>
                                <div className = "product-fav"><FavoriteBorderOutlinedIcon style = {{ verticalAlign: 'middle' }}/></div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <NotFound />
            }
        </>
    );
}

export default Product;