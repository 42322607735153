import React, { useEffect } from 'react';
import './Root.css';
import LandingPage from '../../components/landingpage/LandingPage';
import Extrapolator from '../../components/extrapolator/Extrapolator';
import BrandSlide from '../../components/BrandSlide';

const Root = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <LandingPage />
            <Extrapolator />
            <BrandSlide />
        </>
    );
}

export default Root;