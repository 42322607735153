export const prices = [
    {
        category: "Animale de companie - Consultatii, analize, tratament",
        services: [
            {
                service: "Consultatie",
                price: "50",
            },
            {
                service: "Deplasare teren",
                price: "50",
            },
            {
                service: "Consultatii in afara programului + deplasare la cabinet sau domiciliu (weekend)",
                price: "250",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Eliberare carnet de sanatate",
                price: "35",
            },
            {
                service: "Eliberare pasaport",
                price: "80",
            },
            {
                service: "Microcipare caine",
                price: "50",
            },
            {
                service: "Inregistrare RECS - Registrul de Evidenta al Cainilor cu Stapan",
                price: "25",
            },
            {
                service: "Actualizare date RECS",
                price: "25",
            },
            {
                service: "Transfer Proprietar RECS",
                price: "30",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Profil Hematologie - Sange",
                price: "60",
            },
            {
                service: "Profil Biochimie (14 parametri)",
                price: "160",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Test rapid Parvoviroza",
                price: "75",
            },
            {
                service: "Test rapid Distemper",
                price: "75",
            },
            {
                service: "Test Rapid Giardia",
                price: "80",
            },
            {
                service: "Test rapid CPV/CCV/Giardia",
                price: "110",
            },
            {
                service: "Test Rapid Fiv/Felv",
                price: "100",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Lama Miscroscop - Babesioza",
                price: "50",
            },
            {
                service: "Lama Miscroscop - Dirofilaria ",
                price: "50",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Test 4DX (Dirofilaria, Ehrlichia, Anaplasma, Lyme) Boli transmise de parazitii externi",
                price: "125",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Ecografie (A-fast, T-fast)",
                price: "70",
            },
            {
                service: "Test Fluoresceina (include ambii ochi) ",
                price: "50",
            },
            {
                service: "Test Woods (Examinare Dermatologica)",
                price: "50",
            },
            {
                service: "Clisma (pret in functie de marimea animalului)",
                price: "50",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Eliberare prescriptie medicala / Reteta",
                price: "25",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Tratament injectabil caine / pisica (in functie de greutatea pacientului)",
                price: "35-100",
            },
            {
                service: "Perfuzie pisica/caine sub 10kg",
                price: "80",
            },
            {
                service: "Perfuzie caine 10kg - 20kg",
                price: "120",
            },
            {
                service: "Perfuzie caine 20kg - 40kg",
                price: "180",
            },
            {
                service: "Internare de zi (perfuzie separat)",
                price: "100",
            },
        ],
    },
    {
        category: "Vaccinari - Animale de companie",
        services: [
            {
                service: "Vaccin Bivalent Câine (parvovirus + distemper)",
                price: "70",
            },
            {
                service: "Vaccin polivalent Caine (Distemper, Hepatita Rubart, Parvovirus, Parainfluenta, Leptospiroza)",
                price: "80",
            },
            {
                service: "Vaccin Biocan M (Micosporie)",
                price: "80",
            },
            {
                service: "Vaccin KC (tuse de canisa)",
                price: "80",
            },
            {
                service: "Vaccin antirabic (rabie)",
                price: "60",
            },
            {
                service: "Vaccin anual caine (Consult animal, vaccin polivalent si antirabic)",
                price: "140",
            },
            {
                service: "Vaccin polivalent pisică",
                price: "100",
            },
            {
                service: "Vaccin anual pisică (Cunsult animal, vaccin polivalent și antirabic)",
                price: "160",
            },
        ],
    },
    {
        category: "Interventii chirurgicale (nu include pretul anesteziei)",
        services: [
            {
                service: "Sterilizare pisica",
                price: "200",
            },
            {
                service: "Castrare motan",
                price: "120",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Sterilizare Catea sub 10 kg",
                price: "200",
            },
            {
                service: "Sterilizare Catea intre 10kg - 20kg",
                price: "250",
            },
            {
                service: "Sterilizare Catea intre 20kg - 40kg",
                price: "300",
            },
            {
                service: "Sterilizare Catea mai mare de 40kg",
                price: "350",
            },
            {
                service: "Castrare Caine (mascul) ",
                price: "150",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Extirpare tumora mamara pisica",
                price: "200",
            },
            {
                service: "Extirpare tumora mamara catea",
                price: "de la 200",
            },
            {
                service: "Amputare coada (doar situatii patologice)",
                price: "200-350",
            },
        ],
    },
    {
        category: "Anestezie clasica (NLA - neuroleptanalgezie - caine sau pisica)",
        services: [
            {
                service: "Animal mai mic de 10 kg",
                price: "80",
            },
            {
                service: "Animal intre 10kg - 20kg",
                price: "100",
            },
            {
                service: "Animal intre 20kg - 40 kg",
                price: "150",
            },
            {
                service: "Animal mai mare de 40 kg",
                price: "200",
            },
        ],
    },
    {
        category: "Cosmetica canina si felina",
        services: [
            {
                service: "Tuns catel mai mic de 10kg",
                price: "80-120",
            },
            {
                service: "Spalat (doar catei sub 10kg)",
                price: "40-70",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Tuns catel intre 10kg - 25kg",
                price: "100-200",
            },
            {
                service: "Tuns catel mai mare de 25kg",
                price: "200-350",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Curatare urechi",
                price: "20",
            },
            {
                service: "Vidat Glande Perianale",
                price: "20",
            },
            {
                service: "Taiere unghii",
                price: "20",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Tuns Pisica",
                price: "100",
            },
            {
                service: "Pretul difera in functie de temperament si starea de intretinere a blanii. Durata de la 50 min. la mai bine de 2 ore. In cazul unui animal agresiv, se adauga costul tranchilizarii.",
                price: "",
            },
        ],
    },
    {
        category: "Detartraj",
        services: [
            {
                service: "Detartraj pisica",
                price: "100",
            },
            {
                service: "Detartraj caine sub 15 kg",
                price: "100",
            },
            {
                service: "Detartraj caine peste 15 kg",
                price: "200",
            },
        ],
    },
    {
        category: "Tranchilizare (efect reversibil prin folosirea antidotului - caine sau pisica)",
        services: [
            {
                service: "Animal sub 5 kg",
                price: "50",
            },
            {
                service: "Animal intre 5kg - 10kg",
                price: "80",
            },
            {
                service: "Animal intre 10kg - 20kg",
                price: "150",
            },
            {
                service: "Animal intre 20kg - 40kg",
                price: "180",
            },
            {
                service: "Animal mai mare de 40Kg",
                price: "250",
            },
        ],
    },
    {
        category: "Animale Mari (porci, oi, capre, vaci, cai)",
        services: [
            {
                service: "Consultatie cabinet (cand acest lucru este posibil ex. Miel, Ied, Cal)",
                price: "50",
            },
            {
                service: "Consultatie + Deplasare Teren",
                price: "100",
            },
            {
                service: "Tratament injectabil Intramuscular sau Subcutanat",
                price: "30-100",
            },
            {
                service: "Tratament Perfuzabil",
                price: "50-250",
            },
            {
                service: "Analize Biochimie (14 parametri)",
                price: "160",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Consultanta crestere, alimentatie si nutritie, preventia bolilor, pregatirea si intretinerea adapostului",
                price: "50",
            },
            {
                service: "Eliberare prescriptie / Reteta",
                price: "25",
            },
        ],
    },
    {
        category: "Pasari de curte",
        services: [
            {
                service: "Consultatie pasare la cabinet",
                price: "20",
            },
            {
                service: "Diagnostic post-mortem (examinare cadavru pasare)",
                price: "30",
            },
            {
                service: "Manopera administrare tratament Injectabil",
                price: "10",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Consultanta crestere, alimentatie si nutritie, preventia bolilor, pregatirea si intretinerea adapostului",
                price: "50",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Tratamente injectabile grup de pasari - pret per pasare - medicamentele separat",
                price: "1",
            },
            {
                service: "Administrare Vaccin injectabil grup de pasari - pret per pasare - medicamentele separat",
                price: "1",
            },
            {
                service: "",
                price: "",
            },
            {
                service: "Deplasare la Domiciliu",
                price: "50",
            },
            {
                service: "Eliberare Prescriptie / Reteta",
                price: "25",
            },
        ],
    },
];