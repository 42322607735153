import React, { useState, useEffect } from 'react';
import { useHistory } from  'react-router-dom';
import './Extrapolator.css';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const Extrapolator = () => {

    const history = useHistory();

    const [data, ] = useState({
        types: ['companie', 'rentă'],
        subtypes: {
            'companie': ['căței', 'pisici'],
            'rentă': ['toate'],
        },
        services: {
            'companie': {
                'căței': ['menținerea sănătății', 'diagnostic stare precară', 'castrare/sterilizare', 'cosmetic', 'consultații la domiciliu'],
                'pisici': ['menținerea sănătății', 'diagnostic stare precară', 'castrare/sterilizare', 'cosmetic', 'consultații la domiciliu'],
            },
            'rentă': {
                'toate': [],
            },
        },
    });

    const [type, setType] = useState('-- alege o categorie --');
    const [subtype, setSubtype] = useState('-- alege animal --');
    const [service, setService] = useState('-- alege serviciu --');

    const resetState = (type, value) => {
        switch (type) {
            case 'type':
                setType(value);
                setSubtype('-- alege animal --');
                setService('-- alege serviciu --');
                break;
            case 'subtype':
                setSubtype(value);
                setService('-- alege serviciu --');
                break;
            default:
                break;
        }
    }
  
    const [customClass, setCustomClass] = useState(false);
    const [buttonText, setButtonText] = useState('selectează');

    const extrapolate = () => {
        if (service !== '-- alege serviciu --') {
            history.push('/details', [type, subtype, service]);
        } else {
           // do stuff when one or more fields are empty
        }
    }

    useEffect(() => {
        if (service !== '-- alege serviciu --') {
            setCustomClass(true);
            setButtonText('accesează');
        } else {
            setCustomClass(false);
            setButtonText('selectează');
        }
    }, [service]);

    return (
        <>
            <div className = "classifier-wrapper">
                <div className = "big-wrapper">
                    <div className = "grid-wrapper">
                        <div>
                            <label>Animale: <span>categorii</span></label>
                            <select value = { type } onChange = {e => resetState('type', e.target.value)}>
                                <option disabled>-- alege o categorie --</option>
                                { data.types.map(type => (<option key = { type } value = { type }>{ type }</option>)) }
                            </select>
                        </div>
                        <div>
                            <label>Animal: <span>rasă/specie</span></label>
                            <select value = { subtype } onChange = {e => resetState('subtype', e.target.value)}>
                                <option disabled>-- alege animal --</option>
                                { type === 'companie' || type === 'rentă' ? data.subtypes[type].map(type => (<option key = { type } value = { type }>{ type }</option>)) : null }
                            </select>
                        </div>
                        <div>
                            <label>Servicii:</label>
                            <select value = { service } onChange = {e => setService(e.target.value)}>
                                <option disabled>-- alege serviciu --</option>
                                { (type === 'companie' && (subtype === 'căței' || subtype === 'pisici')) || (type === 'rentă' && subtype === 'toate') ? data.services[type][subtype].map(type => (<option key = { type } value = { type }>{ type }</option>)) : null }
                            </select>
                        </div>
                    </div>
                </div>
                <div className = "small-wrapper">
                    <div className = { `disabled-button ${ customClass ? 'ready-button' : '' }` } onClick = { extrapolate }>
                        <div className = { `inner-button ${ customClass ? 'activated' : '' }` }>
                            { buttonText }
                            <span>
                                <NavigateNextIcon style = {{ marginTop: '10px' }}/>
                                <NavigateNextIcon style = {{ marginLeft: '-12px', marginTop: '15px' }}/>
                                <NavigateNextIcon style = {{ marginLeft: '-12px', marginTop: '15px' }}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Extrapolator;