import React from 'react';
import './Footer.css';
import CopyrightIcon from '@material-ui/icons/Copyright';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const Footer = () => {

    const socialTrigger = type => {
        switch (type) {
            case 'facebook':
                window.open('https://www.facebook.com/VetSuportDobrogea/', '_blank');
                break;
            case 'instagram':
                window.open('https://www.facebook.com/VetSuportDobrogea/', '_blank');
                break;
            case 'whatsapp':
                window.open('tel:0751082187', '_self');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <section className = "footer">
                <div className = "social-plugins">
                    <div className = "x" onClick = { () => socialTrigger('facebook') }>
                        <div className = "facebook"></div>
                        <div className = "separator"></div>
                        <div><span>Scrie-ne pe Facebook</span></div>
                    </div>
                    <div className = "y" onClick = { () => socialTrigger('instagram') }>
                        <div className = "instagram"></div>
                        <div className = "separator"></div>
                        <div><span>Urmărește-ne pe Instagram</span></div>
                    </div>
                    <div className = "z" onClick = { () => socialTrigger('whatsapp') }>
                        <div className = "whatsapp"></div>
                        <div className = "separator"></div>
                        <div><span>Sună 0751082187</span></div>
                    </div>
                </div>
                <div className = "inner-footer">
                    <div>
                        <p><CopyrightIcon style = {{ verticalAlign: 'middle', marginTop: '-2px', fontSize: '18px', fontWeight: 'bold' }}/> vet suport</p>
                        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;dobrogea</p>
                    </div>
                    <div>
                        <p><LocationOnIcon style = {{ verticalAlign: 'middle', fontSize: '28px', marginLeft: '-6px' }}/> <b>topraisar</b></p>
                        <div className = "spacer"></div>
                        <p>DJ391 21</p>
                        <p>Constanța, Romania</p>
                        <p>907285</p>
                        <div className = "spacer"></div>
                        <p><span className = "lowercase email"><a href = "mailto:vetsuportfarm@gmail.com" style = {{ borderBottom: '1px solid black', paddingBottom: '5px' }}>vetsuportfarm@gmail.com</a></span></p>
                        <p className = "lowercase phone"><a href = "tel:+40767700022">0767 700 022 - CLINICA</a></p>
                        <p className = "lowercase phone"><a href = "tel:+40751082187">0751 082 187 - URGENTE</a></p>
                        <div className = "spacer"></div>
                        <p className = "phone"><a href = "https://www.google.com/maps?ll=44.019653,28.459911&amp;z=13&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=embed&amp;daddr=Vet+Suport+Dobrogea+SRL+DJ391+21+Topraisar+907285@44.0196528,28.4599107" target = "_blank" rel = "noopener noreferrer">google maps</a></p>
                    </div>
                    <div>
                        <p><ScheduleIcon style = {{ verticalAlign: 'middle', fontSize: '28px', marginLeft: '-2px' }}/> <b>program</b></p>
                        <div className = "spacer"></div>
                        <p>luni - vineri <span>9:30 - 18:00</span></p>
                        <p>Sâmbată <span>9:30 - 14:00</span></p>
                        <p>Duminică <span>10:00 - 13:00</span></p>
                    </div>
                    <div>
                        <p><span className = "convect">with <FavoriteBorderIcon style = {{ verticalAlign: 'middle', color: '#f8679e' }}/> by Convect</span></p>
                    </div>
                </div>
                <div id = "map-section">
                    <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="555" id="gmap_canvas" src="https://maps.google.com/maps?q=Vet%20Suport%20Dobrogea%20SRL,%20DJ391%2021,%20Topraisar%20907285,%20Romania&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2piratebay.org"></a></div></div>
                </div>
            </section>
        </>
    );
}

export default Footer;