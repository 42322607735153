import React, { Fragment, useState, useEffect } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import FacebookIcon from '@material-ui/icons/Facebook';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const Header = () => {

    const [headerStyle, setHeaderStyle] = useState({ top: '0' });

    useEffect(() => {
        let  threshold: number = 0;
        window.onscroll = () => {
            if (window.scrollY > (threshold + 60)) {
                threshold = window.scrollY;
                setHeaderStyle({ top: '-100px' });
            } else if (window.scrollY < threshold) {
                threshold = window.scrollY;
                setHeaderStyle({ top: '0' });
            } else if (window.scrollY === 0) {
                threshold = 0;
                setHeaderStyle({ top: '0' });
            }
        }
    }, []);

    const [display, setDisplay] = useState('none');
    const [translate, setTranslate] = useState('translateX(-100%)');

    const toggleMenu = () => {
        if (display === 'none') {
            setDisplay('block');

            setTimeout(() => {
                setTranslate('translateX(0)');
            }, 200);
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 300);
        } else {
            setTranslate('translateX(-100%)');
                document.body.style.overflow = 'auto';
            setTimeout(() => { 
                setDisplay('none')
            }, 200);
        }
    }

    return (
        <Fragment>
            <header style = { headerStyle } id = "myheader">
                <div className = "inner-header">
                    <div className = "first-flex">
                        <NavLink to = "/" aria-label="logo"><div className = "logo"></div></NavLink>
                        <ul className = "small-viewport-header">
                            <div className = "viewport-wrapper">
                                <NavLink to = "/" exact activeClassName = "is-active"><li>Acasă</li></NavLink>
                                <NavLink to = "/shop" activeClassName = "is-active"><li>Shop</li></NavLink>
                                <NavLink to = "/tarife" activeClassName = "is-active"><li>Tarife</li></NavLink>
                                <NavLink to = "/contact" activeClassName = "is-active"><li>contact</li></NavLink>
                            </div>
                            <li className = "hamburger" onClick = { toggleMenu }></li>
                        </ul>
                        <ul className = "small-menu-container" style = {{ display: display, transition: '.3s', transform: translate }}>
                            <NavLink to = "/" exact activeClassName = "is-active"><li onClick = { toggleMenu }>Acasă</li></NavLink>
                            <NavLink to = "/shop" activeClassName = "is-active"><li onClick = { toggleMenu }>Shop</li></NavLink>
                            <NavLink to = "/tarife" activeClassName = "is-active"><li onClick = { toggleMenu }>Tarife</li></NavLink>
                            <NavLink to = "/contact" activeClassName = "is-active"><li onClick = { toggleMenu }>contact</li></NavLink>
                        </ul>
                    </div>
                    {/* <NavLink to = "/" aria-label="logo"><div className = "logo"></div></NavLink> */}
                    <div className = "second-flex">
                        <a href = "tel:+40751082187">
                            <li className = "right shop-icons">
                                <PhoneIphone style = {{ marginBottom: '-4px', marginRight: '0px', fontSize: '20px' }}/>
                                <span className = "first">0751 082 187</span>
                            </li>
                        </a>
                        <a href = "https://www.facebook.com/vetsuportdobrogea/" target = "_blank">
                            <li className = "right shop-icons facebook-header">
                                <FacebookIcon style = {{ marginBottom: '-4px', fontSize: '20px' }}/>
                            </li>
                        </a>
                        <a href = "https://www.google.com/maps?ll=44.019653,28.459911&z=13&t=m&hl=en-US&gl=US&mapclient=embed&daddr=Vet+Suport+Dobrogea+SRL+DJ391+21+Topraisar+907285@44.0196528,28.4599107" target = "_blank">
                            <li className = "right shop-icons location-header">
                                <LocationOnIcon style = {{ marginBottom: '-4px', fontSize: '20px' }}/>
                            </li>
                        </a>
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;