import React, { useRef, useState, useEffect } from 'react';
import './LandingPage.css';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const LandingPage = () => {

    const [imgs] = useState([
        'url(https://vetsuportdobrogea.ro/s3/7.jpg)',
        'url(https://vetsuportdobrogea.ro/s3/6.jpg)',
        'url(https://vetsuportdobrogea.ro/s3/11.jpg)',
        'url(https://vetsuportdobrogea.ro/s3/15.jpg)',
        'url(https://vetsuportdobrogea.ro/s3/2.jpg)',
        'url(https://vetsuportdobrogea.ro/s3/pets2.jpeg)',
    ]);

    useEffect(() => {
        let x = 0;
        const interval = setInterval(() => {
            sliderRef.current.style.backgroundImage = imgs[x];
            if (x >= imgs.length - 1) x = 0; 
            else x++;
        }, 5000);
        return () => clearInterval(interval);
    }, [imgs]);

    const myRef = useRef(null);
    const sliderRef = useRef(null);

    const goToRef = ref => {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    }

    return (
        <>
            <div className = "landing-header" ref = { sliderRef }>
                <div className = "landing-inner-container">
                    <div className = "bottom-section">
                        <h1>Partenerul tău în creşterea animalelor</h1>
                        <button onClick = { () => goToRef(myRef) } className = "blink-me">
                            <span><ImportExportIcon style = {{ marginBottom: '-8px', marginRight: '5px', fontSize: '24px' }}/></span>
                            caută serviciu
                        </button>
                    </div>
                </div>
            </div>
            <section className = "reference" ref = { myRef }></section>
        </>
    );
}

export default LandingPage;