export const products = [
    {
        name: "furaj pui starter salvafort",
        category: "furaje",
        description: "Furaj premium recomandat in sistem semi-intensiv profesional pentru pui de gaina cu varsta de 1-21 zile.",
        price: "33ron 10kg",
    },
    {
        name: "furaj pui crestere salvafort",
        category: "furaje",
        description: "Furaj premium recomandat in sistem semi-intensiv profesional pentru pui de gaina cu varsta de 22-35 zile.",
        price: "31ron 10kg",
    },
    {
        name: "furaj pui crestere finisare",
        category: "furaje",
        description: "Furaj standard recomandat pentru uz gospodaresc pentru pui cu varste de la 21 zile la 2 luni.",
        price: "27ron 10kg",
    },
    {
        name: "furaj curci starter salvafort premium faza I",
        category: "furaje",
        description: "Furaj premium recomandat in sistem semi-intensiv profesional pentru curci cu varsta de 0-8 saptamani.",
        price: "42ron 10kg",
    },
    {
        name: "furaj curci starter salvafort premium faza II",
        category: "furaje",
        description: "Furaj premium recomandat in sistem semi-intensiv profesional pentru curci cu varsta de 9-16 saptamani.",
        price: "35ron 10kg",
    },
    {
        name: "concentrat ingrasare porci 30-110 kg",
        category: "furaje",
        description: "Concentrat 33% pentru cresterea porcilor in sistem gospodaresc. Se amesteca 10 kg concentrat cu 20 kg cereale macinate.",
        price: "38ron 10kg",
    },
    {
        name: "concentrat salvafort pentru gaini de oua",
        category: "furaje",
        description: "",
        price: "42ron 10kg",
    },
    {
        name: "Protamino QQRQ furaj mineral concentrat",
        category: "furaje",
        description: "Pentru gaini de oua pentru uz semi-intensiv gospodaresc. Amestesc 5 la 1.",
        price: "30ron 5kg",
    },
    {
        name: "Protamino QQRQ furaj mineral concentrat",
        category: "furaje",
        description: "Pentru gaini de oua pentru uz semi-intensiv gospodaresc. Amestesc 5 la 1.",
        price: "145ron 25kg",
    },
    {
        name: "Protamino Fino furaj mineral concentrat",
        category: "furaje",
        description: "Pentru pui de gaina pentru uz semi-intensiv gospodaresc.",
        price: "35ron 5kg",
    },
    {
        name: "Protamino Fino furaj mineral concentrat",
        category: "furaje",
        description: "Pentru pui de gaina pentru uz semi-intensiv gospodaresc.",
        price: "145ron 25kg",
    },
    {
        name: "Protamino Piggi concentrat starter purcei",
        category: "furaje",
        description: "Pentru purcei cu greutate intre 10 kg si 30 kg.",
        price: "35ron 5kg",
    },
    {
        name: "Protamino Piggi concentrat starter purcei",
        category: "furaje",
        description: "Pentru purcei cu greutate intre 10 kg si 30 kg.",
        price: "155ron 25kg",
    },
    {
        name: "Protamino Premium concentrat ingrasare porci",
        category: "furaje",
        description: "Pentru porci cu greutate de la 30 kg pana la taiere.",
        price: "30ron 5kg",
    },
    {
        name: "Protamino Premium concentrat ingrasare porci",
        category: "furaje",
        description: "Pentru porci cu greutate de la 30 kg pana la taiere.",
        price: "145ron 25kg",
    },
    {
        name: "Protamino Matra concentrat scroafe",
        category: "furaje",
        description: "Previre slabirea si creste cantitatea de lapte.",
        price: "30ron 5kg",
    },
    {
        name: "Protamino Matra concentrat scroafe",
        category: "furaje",
        description: "Previre slabirea si creste cantitatea de lapte.",
        price: "145ron 25kg",
    },
    {
        name: "Introvit A+ oral",
        category: "vitamine",
        description: "Complex aminoacizi si vitamine pentru crestere in uz semi profesional. Se administreaza 1ML pe litru de apa.",
        price: "89ron 1L",
    },
    {
        name: "Introvit amino oral",
        category: "vitamine",
        description: "Complex aminoacizi si vitamine pentru crestere in uz gospodaresc. Se administreaza 1ML pe litru de apa.",
        price: "12ron 100ML",
    },
    {
        name: "Stressol oral",
        category: "vitamine",
        description: "Complex aminoacizi si vitamine pentru perioade de stres precum caldura, vaccinare, relocare.",
        price: "64ron 1L",
    },
    {
        name: "Viusid vet. imunostimulator",
        category: "vitamine",
        description: "Creste capacitatea organismului de aparare impotriva bolilor.",
        price: "126ron 500ML",
    },
    {
        name: "Intertrim",
        category: "antibiotice",
        description: "Antibiotic injectabil.",
        price: "35ron 100ML",
    },
    {
        name: "Interflox",
        category: "antibiotice",
        description: "Antibiotic injectabil.",
        price: "43ron 100ML",
    },
    {
        name: "Penstrep 400",
        category: "antibiotice",
        description: "Antibiotic injectabil.",
        price: "39ron 100ML",
    },
    {
        name: "Introvit E Selen",
        category: "vitamine",
        description: "Vitamina A, E si Seleniu.",
        price: "29ron 100ML",
    },
    {
        name: "Intrafer 200 B12",
        category: "vitamine",
        description: "Fier.",
        price: "48ron 100ML",
    },
    {
        name: "Vitol 140",
        category: "vitamine",
        description: "Continut injectabil cu vitaminele A, D3 si E.",
        price: "35ron 100ML",
    },
    {
        name: "Introvit Multivit",
        category: "vitamine",
        description: "Complex aminoacizi.",
        price: "32ron 100ML",
    },
    {
        name: "Credelio Super Premium",
        category: "antibiotice",
        description: "Deparazitare externa premium.",
        price: "35ron 100ML",
    },
];