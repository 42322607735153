export const services = {
    'căței': {
        'menținerea sănătății': [
            {
                tag: 'h2',
                content: 'Ne bucurăm că doreşti să menții sănătatea tuturor membrilor familiei',
            },
            {
                tag: 'h2',
                content: 'Protejează-ti cățelul impotriva bolilor transmise de la un cățel la altul',
            },
            {
                tag: 'h3',
                content: 'Vaccinarea cățeilor tineri',
            },
            {
                tag: 'p',
                content: ' începe de la vârsta de 6 săptămâni, şi constă în administratrea a 5 vaccinuri, la interval de 2 săptămâni între administrări.',
            },
            {
                tag: 'h3',
                content: 'Vaccinarea cățeilor adulți',
            },
            {
                tag: 'p',
                content: ' dacă nu au fost vaccinați în ultimele 12 luni, constă în administrarea a 2 vaccinuri la interval de 2 săptămâni. Dacă au fost vaccinați în ultimele 12 luni se administrează un singur vaccin.',
            },
            {
                tag: 'h3',
                content: 'Vaccinuri special',
            },
            {
                tag: 'p',
                content: ' se fac în mod deosebit câinilor cu valoare economică mare.',
            },
            {
                tag: 'h2',
                content: 'Protejează-ti intreaga familie',
            },
            {
                tag: 'h3',
                content: 'Deparazitarea externă',
            },
            {
                tag: 'p',
                content: ' prin aceasta eliminăm purecii si căpusele de pe cățel, paraziți care se pot transmite şi la ceilalti membri ai familiei , în special la copii.',
            },
            {
                tag: 'h3',
                content: 'Deparazitarea internă',
            },
            {
                tag: 'p',
                content: ' prin acesta se elimină viermii intestinali , care se pot transmite de la animal la animal, sau de la animal la om.',
            },
            {
                tag: 'h2',
                content: 'La căței în vârstă de peste 7 ani recomandăm efectuarea unui set de analize anuale pentru a evalua funcționarea organelor interne. Orice afecțiune descoperită din timp este mult mai uşor de tratat.',
            },
        ],
        'diagnostic stare precară': [
            {
                tag: 'h2',
                content: 'Cel mai bine este să ajungi cu animalul la cabinet pentru a fi examinat de Un medic',
            },
            {
                tag: 'h3',
                content: 'Situatii in care trebuie sa ajungi de urgenta la cabinet',
            },
            {
                tag: 'p',
                content: ' voma / diaree in mod repetat sau cu sange.',
            },
            {
                tag: 'p',
                content: ' traumatisme (lovituri, accidente).',
            },
            {
                tag: 'p',
                content: ' rani deschise (taieturi, muscaturi, etc).',
            },
            {
                tag: 'p',
                content: ' miaza (viermi prezenti pe rani).',
            },
            {
                tag: 'p',
                content: ' animalul refuza hrana de mai mult de 24 de ore.',
            },
            {
                tag: 'h3',
                content: 'Ce ar ajuta sa stii despre animalul tau inainte de a ajunge la cabinet',
            },
            {
                tag: 'p',
                content: ' cand a mancat ultima oara.',
            },
            {
                tag: 'p',
                content: ' cand a urinat/defecat ultima oara.',
            },
            {
                tag: 'p',
                content: ' de cat timp nu se simte bine.',
            },
            {
                tag: 'p',
                content: ' ce s-a intamplat cu el in ultimele 2-3 zile inainte de a incepe sa dea semne de boala.',
            },
            {
                tag: 'h2',
                content: 'Dupa examinarea animalului la cabinet si efectuarea analizelor in cazul in care este necesar, vom stabili si vom aplica un tratament.',
            },
        ],
        'castrare/sterilizare': [
            {
                tag: 'h2',
                content: 'Ce trebuie sa stiu despre castrarea/sterilizarea unui animal de companie',
            },
            {
                tag: 'h3',
                content: 'Beneficii castrare/sterilizare animal de companie',
            },
            {
                tag: 'h3',
                content: 'La masculi:',
            },
            {
                tag: 'p',
                content: ' dispare instinctul sexual, animalul devine mai prietenos, marcarea teritoriului scade in general, uneori poate disparea.',
            },
            {
                tag: 'h3',
                content: 'La femele:',
            },
            {
                tag: 'p',
                content: ' eviti inmultirea necontrolata a animalelor, contribui la scaderea numarului de animale abandonate pe strazi.',
            },
            {
                tag: 'h2',
                content: 'Castrarea la masculi:',
            },
            {
                tag: 'h3',
                content: 'Căței',
            },
            {
                tag: 'p',
                content: ' se poate face incepand cu varsta de 6 luni.',
            },
            {
                tag: 'h3',
                content: 'Motani',
            },
            {
                tag: 'p',
                content: ' se recomanda dupa ce a ajuns la maturitate, adica dupa varsta de 1 an. Operatia de castrare consta in indepartarea testicolelor. Se face pe baza de programare si cu pregatirea animalului. In momentul cand faci programarea primesti toate detaliile despre pregatirea animalului pentru operatie.',
            },
            {
                tag: 'h2',
                content: 'Sterilizarea la femele:',
            },
            {
                tag: 'p',
                content: ' se face incepand cu varsta de 6 luni.',
            },
            {
                tag: 'p',
                content: ' la animalele care au fatat deja se face dupa ce termina de alaptat.',
            },
            {
                tag: 'p',
                content: ' la animale la care se doreste intreruperea de sarcina, sterilizarea se face la maxim 30 zile de la data montei.',
            },
        ],
        'cosmetic': [
            {
                tag: 'h2',
                content: 'Servicii de cosmetică pentru animalul tău de companie'
            },
            {
                tag: 'h3',
                content: 'Cosmetică pentru căței'
            },
            {
                tag: 'p',
                content: ' Tuns igienic',
            },
            {
                tag: 'p',
                content: ' Tuns iarnă',
            },
            {
                tag: 'p',
                content: ' Curățare urechi',
            },
            {
                tag: 'p',
                content: ' Tăiat unghii',
            },
            {
                tag: 'p',
                content: ' Vidat glande',
            },
        ],
        'consultații la domiciliu': [
            {
                tag: 'h2',
                content: 'Consultații La Domiciliu',
            },
            {
                tag: 'h3',
                content: 'Apelati numarul nostru pentru a stabili impreuna daca o consultatie la domiciliu se preteaza in situatia dvs.',
            },
            {
                tag: 'p',
                content: ' Consultatia unui animal la domiciliu se poate efectua doar in prezenta proprietarului.',
            },
            {
                tag: 'p',
                content: ' Dupa discutia cu medicul veterinar puteti afla daca o consultative la domiciliu se preteaza pentru situatia in care se afla animalul dvs. Acest lucru se stabileste ulterior, avand in vedere ca exista teste si metode de examinare care se pot efectua in teren, dar in acelasi timp exista metode specific de examinare care se pot efectua strict in mediu specializat.',
            },
        ],
    },
    'pisici': {
        'menținerea sănătății': [
            {
                tag: 'h2',
                content: 'Ne bucurăm că doreşti să menții sănătatea tuturor membrilor familiei',
            },
            {
                tag: 'h2',
                content: 'Protejează-ti pisica impotriva bolilor transmise de la o pisica la alta',
            },
            {
                tag: 'h3',
                content: 'Vaccinarea pisicilor tinere',
            },
            {
                tag: 'p',
                content: ' incepe de la varsta de 3 luni, si consta in administratrea a 3 vaccinuri, la interval de 3 saptamani intre administrari.',
            },
            {
                tag: 'h3',
                content: 'Vaccinarea pisicilor adulte',
            },
            {
                tag: 'p',
                content: ' daca nu au fost vaccinati in ultimele 12 luni, consta in administrarea a 2 vaccinuri la interval de 3 saptamani. Daca au fost vaccinati in ultimele 12 luni se administreaza un singur vaccin.',
            },
            {
                tag: 'h3',
                content: 'Vaccinuri special',
            },
            {
                tag: 'p',
                content: ' se fac în mod deosebit pisicilor cu valoare economică mare.',
            },
            {
                tag: 'h2',
                content: 'Protejează-ti pisica si familia',
            },
            {
                tag: 'h3',
                content: 'Deparazitarea externă',
            },
            {
                tag: 'p',
                content: ' prin aceasta eliminăm purecii si căpusele de pe pisica, paraziți care se pot transmite şi la ceilalti membri ai familiei , în special la copii.',
            },
            {
                tag: 'h3',
                content: 'Deparazitarea internă',
            },
            {
                tag: 'p',
                content: ' prin acesta se elimină viermii intestinali , care se pot transmite de la animal la animal, sau de la animal la om.',
            },
            {
                tag: 'h2',
                content: 'La pisici în vârstă de peste 7 ani recomandăm efectuarea unui set de analize anuale pentru a evalua funcționarea organelor interne. Orice afecțiune descoperită din timp este mult mai uşor de tratat.',
            },
        ],
        'diagnostic stare precară': [
            {
                tag: 'h2',
                content: 'Cel mai bine este să ajungi cu animalul la cabinet pentru a fi examinat de Un medic',
            },
            {
                tag: 'h3',
                content: 'Situatii in care trebuie sa ajungi de urgenta la cabinet',
            },
            {
                tag: 'p',
                content: ' voma / diaree in mod repetat sau cu sange.',
            },
            {
                tag: 'p',
                content: ' traumatisme (lovituri, accidente).',
            },
            {
                tag: 'p',
                content: ' rani deschise (taieturi, muscaturi, etc).',
            },
            {
                tag: 'p',
                content: ' miaza (viermi prezenti pe rani).',
            },
            {
                tag: 'p',
                content: ' animalul refuza hrana de mai mult de 24 de ore.',
            },
            {
                tag: 'h3',
                content: 'Ce ar ajuta sa stii despre animalul tau inainte de a ajunge la cabinet',
            },
            {
                tag: 'p',
                content: ' cand a mancat ultima oara.',
            },
            {
                tag: 'p',
                content: ' cand a urinat/defecat ultima oara.',
            },
            {
                tag: 'p',
                content: ' de cat timp nu se simte bine.',
            },
            {
                tag: 'p',
                content: ' ce s-a intamplat cu el in ultimele 2-3 zile inainte de a incepe sa dea semne de boala.',
            },
            {
                tag: 'h2',
                content: 'Dupa examinarea animalului la cabinet si efectuarea analizelor in cazul in care este necesar, vom stabili si vom aplica un tratament.',
            },
        ],
        'castrare/sterilizare': [
            {
                tag: 'h2',
                content: 'Ce trebuie sa stiu despre castrarea/sterilizarea unui animal de companie',
            },
            {
                tag: 'h3',
                content: 'Beneficii castrare/sterilizare animal de companie',
            },
            {
                tag: 'h3',
                content: 'La masculi:',
            },
            {
                tag: 'p',
                content: ' dispare instinctul sexual, animalul devine mai prietenos, marcarea teritoriului scade in general, uneori poate disparea.',
            },
            {
                tag: 'h3',
                content: 'La femele:',
            },
            {
                tag: 'p',
                content: ' eviti inmultirea necontrolata a animalelor, contribui la scaderea numarului de animale abandonate pe strazi.',
            },
            {
                tag: 'h2',
                content: 'Castrarea la masculi:',
            },
            {
                tag: 'h3',
                content: 'Căței',
            },
            {
                tag: 'p',
                content: ' se poate face incepand cu varsta de 6 luni.',
            },
            {
                tag: 'h3',
                content: 'Motani',
            },
            {
                tag: 'p',
                content: ' se recomanda dupa ce a ajuns la maturitate, adica dupa varsta de 1 an. Operatia de castrare consta in indepartarea testicolelor. Se face pe baza de programare si cu pregatirea animalului. In momentul cand faci programarea primesti toate detaliile despre pregatirea animalului pentru operatie.',
            },
            {
                tag: 'h2',
                content: 'Sterilizarea la femele:',
            },
            {
                tag: 'p',
                content: ' se face incepand cu varsta de 6 luni.',
            },
            {
                tag: 'p',
                content: ' la animalele care au fatat deja se face dupa ce termina de alaptat.',
            },
            {
                tag: 'p',
                content: ' la animale la care se doreste intreruperea de sarcina, sterilizarea se face la maxim 30 zile de la data montei.',
            },
        ],
        'cosmetic': [
            {
                tag: 'h2',
                content: 'Servicii de cosmetică pentru animalul tău de companie'
            },
            {
                tag: 'h3',
                content: 'Cosmetică pentru pisici'
            },
            {
                tag: 'p',
                content: ' Tuns igienic',
            },
            {
                tag: 'p',
                content: ' Tuns iarnă',
            },
            {
                tag: 'p',
                content: ' Curățare urechi',
            },
            {
                tag: 'p',
                content: ' Tăiat unghii',
            },
            {
                tag: 'p',
                content: ' Vidat glande',
            },
        ],
        'consultații la domiciliu': [
            {
                tag: 'h2',
                content: 'Consultații La Domiciliu',
            },
            {
                tag: 'h3',
                content: 'Apelati numarul nostru pentru a stabili impreuna daca o consultatie la domiciliu se preteaza in situatia dvs.',
            },
            {
                tag: 'p',
                content: ' Consultatia unui animal la domiciliu se poate efectua doar in prezenta proprietarului.',
            },
            {
                tag: 'p',
                content: ' Dupa discutia cu medicul veterinar puteti afla daca o consultative la domiciliu se preteaza pentru situatia in care se afla animalul dvs. Acest lucru se stabileste ulterior, avand in vedere ca exista teste si metode de examinare care se pot efectua in teren, dar in acelasi timp exista metode specific de examinare care se pot efectua strict in mediu specializat.',
            },
        ],
    },
}