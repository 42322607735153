import React, { useEffect } from 'react';
import { prices } from '../../services/prices';
import './Tarife.css';

const Tarife = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id = "tarife">
                <div className = "board">
                    {
                        prices.map((item, idx) => (<div className = "wrapper-item" key = { idx }>
                            <div className = "section-divider">
                                <div className = "category">{ item.category }</div>
                                <div className = "price">RON</div>
                            </div>
                            {item.services.map((service, index) => (<div key = { index }>
                                <div className = "item">
                                    <div className = "category">{ service.service }</div>
                                    <div className = "price">{ service.price }</div>
                                </div>
                            </div>))}
                        </div>))
                    }
                </div>
            </div>
        </>
    );
}

export default Tarife;