import React, { useEffect } from 'react';
import './Contact.css';
import Form from '../../components/form/Form';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className = "contact-page">
                <div className = "contact-top-section">
                    <div className = "absolute-text-container">
                        <h1>Contact</h1>
                        <p>
                            Ai întâmpinat o problemă sau ai întrebări legate de serviciile sau produsele noastre?
                            Ne poţi suna sau scrie un mesaj iar noi te vom suna înapoi.
                        </p>
                    </div>
                </div>
                <div className = "inner-contact">
                    <div className = "contact-header">
                        Topraisar
                    </div>
                    <div className = "map-section">
                        <iframe
                            title = "map"
                            width = "100%"
                            height = "100%"
                            id = "gmap_canvas"
                            src="https://maps.google.com/maps?q=DJ391%2021,%20Topraisar%20907285,%20Romania&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0">
                        </iframe>
                    </div>
                    <div className = "address-section">
                        <div className = "address-one">
                            <p>Topraisar</p>
                            <p>DJ391 21</p>
                            <p>Constanța, Romania</p>
                            <p>907285</p>
                        </div>
                        <div className = "address-two">
                            <p className = "phone"><a href = "tel:+40767700022">0767 700 022 - CLINICA</a></p>
                            <p className = "phone"><a href = "tel:+40751082187">0751 082 187 - URGENTE</a></p>
                            <p className = "lowercase phone"><a href = "mailto:vetsuportfarm@gmail.com"  style = {{ borderBottom: '1px solid black', paddingBottom: '5px' }}>vetsuportfarm@gmail.com</a></p>
                        </div>
                    </div>
                </div>
                <div className = "form-section">
                    <div className = "inner-form-section">
                        <Form />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;