import React, { useRef } from 'react';
import './BrandSlide.css';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const BrandSlide = () => {

    const companyServices = [
        "Deparazitari interne si externe pentru catei si pisici.",
        "Vaccinari animale tinere, adulte si vaccinuri special.",
        "Interventii de urgenta la cabinet.",
        "Sterilizari si castrari pisici si catei.",
        "Servicii cosmetice - tuns iarna sau igienic.",
        "Curatare urechi, taiat unghii, vidat glande.",
        "Produse de farmacie veterinara, hrana animale si accesorii.",
        "Interventii rapide la domiciliu.",
    ];

    const serviceRef = useRef(null);

    const scrollOnClick = direction => direction === 'right' ? serviceRef.current.scrollLeft += 490 : serviceRef.current.scrollLeft -= 490;

    return (
        <>
            <section className = "brand-slide">
                <div className = "brand-wrapper">
                    <div className = "div-wrapper">
                        <div className = "img1"></div>
                        <h1>Explorează servicii</h1>
                        <p>Îți punem la dispoziție o platformă digitală cu acces instant către servicii şi soluții personalizate.</p>
                    </div>
                    <div className = "div-wrapper">
                        <div className = "img2"></div>
                        <h1>Magazin online</h1>
                        <p>Aici poți găsi produse de farmacie veterinară cât si hrană pentru orice categorie de animale.</p>
                    </div>
                    <div className = "div-wrapper">
                        <div className = "img3"></div>
                        <h1>Venim la tine</h1>
                        <p>Specializarea noastră constă în execuția deplasărilor la domiciliu în Dobrogea.</p>
                    </div>
                </div>
            </section>
            <div className = "service-advertise">
                <h1>Oferim Servicii Premium Clienților Noștri</h1>
                <p>Cunoaștem necesitatea stabilirii unei legături bazate pe încredere 
                    între deținătorii de animăluțe și medicii acestora. Tocmai de aceea 
                    ne dorim sa vă clarificăm orice nelămurire, să vă răspundem întrebărilor 
                    legate de protocoalele de tratament și să vă oferim informații cât mai 
                    exacte privind starea de sănătate a animalului pe care îl aveți în îngrijire.
                </p>
            </div>
            <div className = "service-advertise">
                <div className = "small-advertiser">
                    <div className = "advertise-box">
                        <h1>3700+</h1>
                        <p>clienți anual</p>
                    </div>
                    <div className = "advertise-box">
                        <h1>250+</h1>
                        <p>intervenții domiciliu</p>
                    </div>
                </div>
            </div>
            <div className = "service-wrapper">
                <button className = "absolute-button-left" onClick = { () => scrollOnClick('left') }>
                    <KeyboardArrowLeftIcon style = {{ fontSize: '50px', marginLeft: '-15px'}}/>
                </button>
                <button className = "absolute-button-right" onClick = { () => scrollOnClick('right') }>
                    <KeyboardArrowRightIcon style = {{ fontSize: '50px'}}/>
                </button>
                <div ref = { serviceRef } className = "services-panel">
                    { companyServices.map((service, idx) => (<div key = { idx }>{ service }</div>)) }
                </div>
            </div>
        </>
    );
}

export default BrandSlide;