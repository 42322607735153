import React, { useState, useEffect } from 'react';
import './NotFound.css';

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goToStartPage = () => {
        window.open('/', '_self');
    }

    const [timer, setTimer] = useState(10);

    useEffect(() => {
        const myInterval = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        if (timer === 0) window.open('/', '_self');
        return () => clearTimeout(myInterval);
    }, [timer]);

    return (
        <>
            <div className = "not-found">
                <div className = "four-zero-four">
                    404
                </div>
                <div className = "not-found-info">
                    Ne pare rău, această pagină nu a fost găsită. Mergi la pagina de <span onClick = { goToStartPage }>start</span>.
                </div>
                <span>Redirectionare in { timer } secunde..</span>
            </div>
        </>
    );
}

export default NotFound;