import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Root from './routes/root/Root';
import Shop from './routes/shop/Shop';
import Tarife from './routes/tarife/Tarife';
import Contact from './routes/contact/Contact';
import Details from './routes/details/Details';
import Product from './routes/product/Product';
// import Admin from './routes/admin/Admin';
import NotFound from './routes/notfound/NotFound';
import Footer from './components/footer/Footer';

const App = () => {

    return (
        <>
            <Router>
                <Header />
                <Switch>
                    <Route exact path = "/" component = { Root }></Route>
                    <Route path = "/shop" component = { Shop }></Route>
                    <Route path = "/tarife" component = { Tarife }></Route>
                    <Route path = "/contact" component = { Contact }></Route>
                    <Route path = "/details" render = { () => <Details /> }></Route>
                    <Route path = "/product" render = { () => <Product /> }></Route>
                    {/* <Route path = "/admin" render = { () => <Admin /> }></Route> */}
                    <Route component = { NotFound }></Route>
                </Switch>
                <Footer />
            </Router>
        </>
    );
}

export default App;