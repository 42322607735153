import React, { useEffect, useState } from 'react';
import './Shop.css';
import { useHistory } from  'react-router-dom';
import { products } from '../../products/products';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

const Shop = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();

    const expandProduct = arg => {
        const { name, category, description, price} = arg;
        history.push('/product', { name, category, description, price });
    }

    const [categories, setCategories] = useState([]);
    const [showCat, setShowCat] = useState("none");

    useEffect(() => {
        products.forEach(prod => categories.includes(prod.category) ? "" : setCategories([...categories, prod.category]));
    }, [categories]);

    const showCatList = () => {
        switch (showCat) {
            case "none":
                setShowCat("inline");
                break;
            case "inline":
                setShowCat("none");
                break;
            default:
                break;
        }
    }

    const filterCat = cat => {
        const newProducts = products.filter(prod => prod.category === cat);
        setAllProducts([...newProducts]);
    }

    const resetCats = () => {
        setAllProducts([...products]);
    }

    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        setAllProducts([...products]);
    }, []);

    return (
        <>  
            <div className = "under-construction">Pagină temporar indisponibilă..</div>
            <div className = "shop-header">
                <li className = "cat-select" onClick = { showCatList }>categorii</li>
                <div className = "cat-list" style = {{ display: showCat }}>
                    { categories.map((cat, idx) => (<span onClick = { () => filterCat(cat) } key = { idx }>#{ cat }</span>)) }
                    <span onClick = { resetCats }>#toate</span>
                </div>
            </div>
            <section className = "shop-type">
                { allProducts.map((prod, idx) => (<div className = "shop-item" key = { idx }>
                    <div className = "product-img" onClick = { () => expandProduct(prod) }></div>
                    <div className = "product-name" onClick = { () => expandProduct(prod) }>{ prod.name }</div>
                    <div className = "product-cat">{ `#${prod.category}` }</div>
                    <div className = "product-price">{ prod.price }</div>
                    <div className = "product-actions">
                        <div className = "product-add"><LocalMallOutlinedIcon style = {{ verticalAlign: 'middle', marginRight: '15px' }}/> Adaugă în coş</div>
                        <div className = "product-fav"><FavoriteBorderOutlinedIcon style = {{ verticalAlign: 'middle' }}/></div>
                    </div>
                </div>)) }
            </section>
        </>
    );
}

export default Shop;
