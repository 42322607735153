import React, { useEffect } from 'react';
import { useLocation } from  'react-router-dom';
import NotFound from '../notfound/NotFound';
import { services } from '../../services/services';
import './Details.css';

const Details = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { state } = useLocation();

    const callToAction = () => {
        window.open('tel:0751082187', '_self');
    }

    return (
        <>
            { state ?
                <section id = "target-page">
                    <div className = "target-page-header">
                        <h1>animale de { state[0] }</h1>
                        <h2 style = {{ fontWeight: '300' }}>{ state[1] } / { state[2] }</h2>
                    </div>
                    <div className = "inner-target">
                        <div className = "target-content">
                            {
                                services[state[1]][state[2]].map((item, idx) => React.createElement(item.tag, { key: idx }, item.content))
                            }
                        </div>
                        <button onClick = { callToAction }>sună şi programează</button>
                    </div>
                </section>
                :
                <NotFound />
            }
        </>
    );
}

export default Details;